<template>
  <div class="grid mt-1">
    <div id="shipment_form" class="card p-fluid p-inputtext-sm md:col-6">
      <h5 style="font-weight:bold;">{{ $t('package.addPackageToShipment', {shipmentCode: shipment.shipmentCode})}}</h5>
      <div class="field grid">
        <label for="agentCode" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.referenceCode')}}</label>
        <div class="col-12 md:col-9">{{shipment.referenceCode}}</div>
      </div>
      <div class="field grid">
        <label for="awbCode" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.awbCode')}}</label>
        <div class="col-12 md:col-9">{{shipment.awbCode}}</div>
      </div>
      <div class="field grid">
        <label for="type" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('package.receiptCode')}}</label>
        <div class="col-12 md:col-9">
          <Dropdown id="receiptCode" 
            v-model="entity.receiptCode" 
            :options="receipts" 
            optionLabel="receiptCode" 
            optionValue="receiptCode"
            :placeholder="$t('package.receiptCode')" />
        </div>
      </div> 
      <div class="field grid"> 
        <label for="packageCode" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('package.packageCode')}}</label>
        <form-field :message="errors.packageCode" class="col-12 md:col-9">
          <InputText ref="packageCode" id="packageCode" v-model.trim="entity.packageCode" :required="true" autofocus />
        </form-field>
      </div>
      <div class="field grid">
        <label for="weightActualKg" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('package.weightActualKg')}}</label>
        <form-field :message="errors.weightActualKg" class="col-12 md:col-9">
          <InputNumber ref="weightActualKg" id="weightActualKg" v-model.trim="entity.weightActualKg" :required="true" autofocus />
        </form-field>
      </div>
      <div class="field grid">
        <label for="type" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('package.goodsType')}}</label>
        <form-field :message="errors.type" class="col-12 md:col-9">
          <Dropdown id="pkgType" 
            v-model="entity.type" 
            :options="packageTypes"
            optionLabel="lablel"
            optionValue="value"
            :placeholder="$t('package.goodsType')" />
        </form-field>
      </div>
      <div class="field grid">
        <label for="processStatus" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('package.processStatus')}}</label>
        <div class="col-12 md:col-9">
          <Dropdown id="processStatus" 
            v-model="entity.processStatus" 
            :options="processStatuses" 
            optionLabel="lablel" 
            optionValue="value"
            :placeholder="$t('package.processStatus')" />
        </div>
      </div> 
      <div class="field grid">
        <label for="appearance" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('package.appearance')}}</label>
        <form-field :message="errors.appearance" class="col-12 md:col-9">
          <Dropdown id="processStatus" 
            v-model="entity.appearance" 
            :options="appearances" 
            optionLabel="lablel" 
            optionValue="value"
            :placeholder="$t('package.appearance')" />
        </form-field>
      </div>
      <div class="field grid">
        <label for="complaint" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('package.complaint')}}</label>
        <form-field :message="errors.complaint" class="col-12 md:col-9">
          <Dropdown id="processStatus" 
            v-model="entity.complaint" 
            :options="complaints" 
            optionLabel="lablel" 
            optionValue="value"
            :placeholder="$t('package.complaint')" />
        </form-field>
      </div>
      <div class="field grid">
        <label for="description" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('package.description')}}</label>
        <div class="col-12 md:col-9">
          <Textarea id="description" v-model="entity.description" :required="false" rows="5" cols="20" />
        </div>
      </div>
      <div class="field grid">
        <label for="note" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('package.note')}}</label>
        <div class="col-12 md:col-9">
          <Textarea id="note" v-model="entity.note" :required="entity.type == 'special'" rows="5" cols="20" />
        </div>
      </div>
      <div class="field grid">
        <div class="col-3"/>
        <Toolbar class="col-9 my-2" style="border:0px; padding:0rem 0rem 0rem 0.5rem">
          <template #start>
            <Button :label="$t('button.save')" icon="pi pi-save" @click="onSave($event)"/>
            <i class="mr-2" />
            <!-- <Button :label="$t('button.reset')" icon="pi pi-replay" @click="onReset($event)"/>
            <i class="mr-2" /> -->
          </template>
        </Toolbar>
      </div>  
   </div> 
  </div>
</template>
<script>
import FormField from '@/components/form/FormField';
import ShipmentService from "@/service/ShipmentService";
import ReceiptService from "@/service/ReceiptService";
import PackageService from "@/service/PackageService";
import mixin_form from '@/mixins/form';
import adjustment from "@/utils/adjustment";
import { PackageProcessStatuses, PackageAppearances, PackageComplaints } from "@/utils/consts";
export default {
  components: {FormField},
  mixins:[mixin_form],
  created(){
    
  },
  data() {
    return {
      shipment: {},
      submitted: false,
      packageTypes: [
        { "value": "normal", "lablel": "Thường" }, 
        { "value": "special", "lablel": "Đặc biệt" }
      ],
      processStatuses: PackageProcessStatuses,
      appearances: PackageAppearances,
      complaints: PackageComplaints,
      packageCodes: [],
      receiptCodes: [],
      shipmentCodes: [],
      receipts: [],
      entity: {
        type: "normal",
        processStatus: "Đang về",
        appearance: "Bình thường",
        complaint: "Không",
        description: "",
        note: ""
      },
      errors: {},
    }
  },
  async mounted() {
    if (this.$route.params.shipmentId) {
      this.shipment = await ShipmentService.get(this.$route.params.shipmentId);
      this.entity.shipmentCode = this.shipment.shipmentCode;
      this.entity.shipmentId = this.shipment.id;
      this.entity.agentCode = this.shipment.agentCode;
      this.entity.awbCode = this.shipment.awbCode;
      const receipts = await ReceiptService.getAgentReceipts(this.shipment.agentCode);
      this.receipts = receipts.content;
    }
  },
  methods: {
    async onChangeAgentCode(event) {
      const response = await ShipmentService.getNextShipmentCode(event.value);
      this.entity.shipmentCode = response.data;    
    },
    async onSave() {
      if(!this.submitted) {
        this.errors = this.validate(this.entity, 'package');
        if(Object.keys(this.errors).length == 0) {
          this.submitted = true;
          const savedPackage = await PackageService.create(this.entity).catch((e) => {
            console.log(e);
          });
          this.entity = adjustment.adjustPackage(savedPackage);
          this.$router.push({ name: 'agent_shipment_detail', params: {id: this.$route.params.shipmentId} });
         
        }
      }
    }
  }
}
</script>
